<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">


                <tchtree v-model="searchForm.class_ids" ref="classTree"></tchtree>
                <el-date-picker v-model="searchForm.search_date" size="small" type="daterange" align="right" unlink-panels
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-input placeholder="学生姓名/学号" v-model="searchForm.keyword" size="small" class="input-with-select ml10"
                    style="width:200px" clearable>
                </el-input>


                <el-select v-model="searchForm.send_status" clearable placeholder="邮寄状态" size="small"
                    @change="$forceUpdate()" style="width:130px;margin-left:10px">
                    <el-option label="未邮寄" :value="0"></el-option>
                    <el-option label="已邮寄" :value="1"></el-option>
                </el-select>
                <!-- <el-select v-model="searchForm.send_sms" clearable placeholder="短信提醒" size="small"
                    @change="changeStatus" style="width:130px;margin-left:10px">
                    <el-option label="未发送" :value="0"></el-option>
                    <el-option label="已发送" :value="1"></el-option>
                </el-select> -->

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">搜索</el-button>
            </div>

            <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">

                <!-- <el-button icon="el-icon-download" style="margin-left:10px" size="small" type="text">
                    <a href="/xls/实习信息导入模板.xlsx" style="color:inherit;">下载模板</a>
                </el-button>
                <el-upload style="display: inline-block; margin-right: 10px" action :http-request="uploadTemp"
                    :on-success="uploadFiles" :show-file-list="false" name="image">
                    <el-button icon="el-icon-position" style="margin-left:10px" size="small" @click="getList" type="primary"
                        plain>导入</el-button>
                </el-upload> -->

                <!-- <el-button icon="el-icon-download" size="small" @click="exportWordOrPdf">导出</el-button> -->

                <!-- <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary" -->
                <!-- @click="tongbuJava">迁移老版本</el-button> -->


            </div>

        </el-row>

        <div class="dividerBar"></div>

        <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="55px" align="center" />
            <el-table-column label="学号" prop="stu_code" width="135" align="center" />
            <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center" />
            <el-table-column label="所在班级" prop="class_name" show-overflow-tooltip width="120px" align="center" />
            <el-table-column label="系部" prop="yx_name" show-overflow-tooltip width="150px" align="center" />

            <el-table-column label="收件人" prop="recipient" min-width="200px" align="center" show-overflow-tooltip />
            <el-table-column label="收件人联系电话" prop="contact_number" width="135" align="center" />
            <el-table-column label="报到证邮寄地址" prop="send_address" min-width="180" align="center" show-overflow-tooltip />
            <el-table-column label="报到证邮寄单号" prop="mailing_num" width="150" align="center" />
            <el-table-column label="报到证邮寄状态" prop="send_status" width="100px" align="center">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.send_status == 0" type="danger">未邮寄</el-tag>
                    <el-tag size="mini" v-if="scope.row.send_status == 1" type="success">已邮寄</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column label="短信提醒" prop="send_sms" width="100px" align="center">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.send_status == 0" style="color:red">未发送</el-tag>
                    <el-tag size="mini" v-if="scope.row.send_status == 1" style="color:green">已发送</el-tag>
                </template>
            </el-table-column> -->
            <el-table-column label="单号导入时间" prop="dh_create_date" show-overflow-tooltip width="180" align="center">
                <!-- <template slot-scope="scope">{{ scope.row.dh_create_date ? scope.row.dh_create_date.split(" ")[0] : '' }}</template> -->
            </el-table-column>
            <el-table-column label="创建时间" prop="create_date" show-overflow-tooltip width="180" align="center">
                <!-- <template slot-scope="scope">{{ scope.row.create_date ? scope.row.create_date.split(" ")[0] : '' }}</template> -->
            </el-table-column>
            <el-table-column label="创建人" prop="username" width="100px" align="center" />
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <!-- <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini">短信提醒</el-tag> -->
                    <el-tag class="pointer" @click="view(scope.row)" type="primary" size="mini">查看</el-tag>
                    <!-- <el-tag  class="pointer ml10" @click="handleModify(scope.row, 'edit')" type="success" size="mini">修改</el-tag> -->
                    <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                        iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                        <el-tag class="pointer ml10" slot="reference" size="mini" type="danger">删除</el-tag>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
        <!-- 详情 -->
        <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" custom-class="cus_dialog"
            width="600px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">报到证邮寄</span>
            </div>
            <div class="dialog_right">
                <div class="flexStart"
                    style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">学号</div>
                        <div class="cell flexStart flex_1">{{ formData.stu_code }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">姓名</div>
                        <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">接收单位名称</div>
                        <div class="cell flexStart flex_1">{{ formData.recipient }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">接收人联系电话</div>
                        <div class="cell flexStart flex_1">{{ formData.contact_number }}</div>
                    </div>
                    <div class="lineRow flexStretch" style="width:100%;">
                        <div class="cell flexStart">EMS运单号</div>
                        <div class="cell flexStart flex_1">{{ formData.mailing_num }}
                            <img class="pointer" src="../../../public/img/daInquiry_copy.png" alt=""
                                style="width: 20px;height: 20px;margin-left: 10px;" @click="onCopy">

                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { exportWord } from "../../utils/exportword";
import tchtree from "../com/tchTree.vue";
import { cls_sxsp_export_to_excel } from "../../../public/js/Export2Excel";
import citys from "../../utils/arae_value.js";

export default {
    components: { tchtree },
    data() {
        return { 
            searchForm: {  },
            tableData: [],
            dialogForm: false,
            formData: {},
            
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        };
    },
    mounted() {
        this.getList();

    },

    methods: {

        getList() {
            this.$http.post("/api/archive_ems_list", {
                type: 2,
                start_date: (this.searchForm.search_date && this.searchForm.search_date.length > 0) ? this.searchForm.search_date[0] : '',
                end_date: (this.searchForm.search_date && this.searchForm.search_date.length > 0) ? this.searchForm.search_date[1] : '',
                class_ids: this.searchForm.class_ids,
                send_status: this.searchForm.send_status,
                del_flag: 0,
            }).then(res => {
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },

        view(row) {
            this.formData = JSON.parse(JSON.stringify(row))
            this.dialogForm = true
        },
        handleDel(row) {
            this.$http
                .post("/api/archive_ems_delete", {
                    id: row.id
                })
                .then(res => {
                    this.$message.success("删除成功");
                    this.getList();
                });
        },

        onCopy() {
            var input = document.createElement("input"); // 创建input对象
            input.value = this.formData.mailing_num; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success("复制成功~");
        },


        // 导入
        uploadFiles(e) {
            this.$http
                .post("/api/archive_ems_import", {
                    url: e.src
                })
                .then(res => {
                    this.$message.success("导入成功");
                    if (res.data.errmsg && res.data.errmsg.length > 0) {
                        let html = "";
                        for (let msg of res.data.msg) {
                            html +=
                                "<div style='font-size:12px'>" +
                                msg["姓名"] +
                                ":" +
                                msg.msg +
                                "</div>";
                        }
                        this.$alert(html, "未导入数据", {
                            dangerouslyUseHTMLString: true
                        });
                    }
                    this.getList();
                });
        },




        tongbuJava() {
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_stu_enter", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },
        exportWordOrPdf() { },


    }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
    padding: 0px 21px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}

/deep/ .el-tabs__item {
    font-size: 18px;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 150px;
            padding-left: 8px;
        }

    }

    .imgaddbtn {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dotted #ccc;
        font-size: 30px;
        cursor: pointer;
    }

    .imgremove {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;

        cursor: pointer;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
    }

    .imgremove:hover {
        color: orange;
    }
}
</style>